require("./scss/style.scss");

$(window).on("load",function(){$("#status").fadeOut(),$("#preloader").delay(0).fadeOut("slow"),$("body").delay(0).css({overflow:"visible"})}),
$(document).ready(function(){$(window).scrollTop($(window).scrollTop()+1);var o=$("#nav-top").outerHeight();$(window).scroll(function(){$(this).scrollTop()>o?($("#header").addClass("header-fixed"),$(".navbar").css({"background-color":"rgba(255,255,255,1)","box-shadow":"0px 2px 5px rgba(0,0,0,0.1)"}),$(".dropdown-menu").css({"background-color":"rgba(255,255,255,1)"})):($("#header").removeClass("header-fixed"),$(".navbar").css("background-color","rgba(255,255,255,1)"),$(".dropdown-menu").css("background-color","rgba(255,255,255,1)"))}),$(window).scroll(function(){$(this).scrollTop()>o+50?$(".header-fixed #nav-agendamento2").css({visibility:"visible",opacity:"1"}):$(".header-fixed #nav-agendamento2").css({visibility:"hidden",opacity:"0"})}),$(window).scroll(function(){$(this).scrollTop()>350?$(".scroll_top").fadeIn():$(".scroll_top").fadeOut()}),$(".scroll_top").click(function(){return $("html, body").animate({scrollTop:0},1500),!1}),$("#search-hideshow").on("click",function(){$("#search").toggleClass("active")}),
$("button#accept-cookies").click(function(o){o.preventDefault();var e=new Date;e.setTime(e.getTime()+94776479999.99998),
$("#termo").hide(),$.cookie("accept","true",{path:"/",expires:e})})}),
$(window).on("load",function(){$("#status").fadeOut(),$("#preloader").delay(350).fadeOut("slow"),$("body").delay(350).css({overflow:"visible"})}),$("a.anchor").not('[href="#"]').not('[href="#0"]').click(function(o){if(location.pathname.replace(/^\//,"")==this.pathname.replace(/^\//,"")&&location.hostname==this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(console.log(e),o.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-77},1500,function(){var o=$(e);if(o.focus(),o.is(":focus"))return!1;o.attr("tabindex","-1"),o.focus()}))}});var target=(target=window.location.hash).replace("#","");window.location.hash="",$(window).on("load",function(){target&&$("html, body").animate({scrollTop:$("#"+target).offset().top-77},1500,"swing",function(){})});

$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
  }
  var $subMenu = $(this).next(".dropdown-menu");
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    $('.dropdown-submenu .show').removeClass("show");
  });
  return false;
});

const menu = document.querySelector(".menu");
const menuMain = menu.querySelector(".menu-main");
const goBack = menu.querySelector(".go-back");
const menuTrigger = document.querySelector(".mobile-menu-trigger");
const closeMenu = menu.querySelector(".mobile-menu-close");
let subMenu;
menuMain.addEventListener("click", (e) =>{
  if(!menu.classList.contains("active")){
    return;
  }
  if(e.target.closest(".menu-item-has-children")){
     const hasChildren = e.target.closest(".menu-item-has-children");
     showSubMenu(hasChildren);
  }
});
goBack.addEventListener("click",() =>{
   hideSubMenu();
})
menuTrigger.addEventListener("click",() =>{
   toggleMenu();
})
closeMenu.addEventListener("click",() =>{
   toggleMenu();
})
document.querySelector(".menu-overlay").addEventListener("click",() =>{
  toggleMenu();
})
function toggleMenu(){
  menu.classList.toggle("active");
  document.querySelector(".menu-overlay").classList.toggle("active");
}
function showSubMenu(hasChildren){
   subMenu = hasChildren.querySelector(".sub-menu");
   subMenu.classList.add("active");
   subMenu.style.animation = "slideLeft 0.5s ease forwards";
   const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
   menu.querySelector(".current-menu-title").innerHTML=menuTitle;
   menu.querySelector(".mobile-menu-head").classList.add("active");
}

function  hideSubMenu(){  
   subMenu.style.animation = "slideRight 0.5s ease forwards";
   setTimeout(() =>{
      subMenu.classList.remove("active");	
   },300); 
   menu.querySelector(".current-menu-title").innerHTML="";
   menu.querySelector(".mobile-menu-head").classList.remove("active");
}

window.onresize = function(){
  if(this.innerWidth >991){
    if(menu.classList.contains("active")){
      toggleMenu();
    }

  }
}

(() => {
    const getCookie = (name) => {
        const value = " " + document.cookie;
        console.log("value", `==${value}==`);
        const parts = value.split(" " + name + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
    };
  
    const setCookie = function (name, value, expiryDays, domain, path, secure) {
        const exdate = new Date();
        exdate.setHours(
            exdate.getHours() +
            (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
        );
        document.cookie =
            name +
            "=" +
            value +
            ";expires=" +
            exdate.toUTCString() +
            ";path=" +
            (path || "/") +
            (domain ? ";domain=" + domain : "") +
            (secure ? ";secure" : "");
    };
  
    const $cookiesBanner = document.querySelector(".cookies-eu-banner");
    const $cookiesBannerButton = $cookiesBanner.querySelector("button");
    const cookieName = "cookiesBanner";
    const hasCookie = getCookie(cookieName);
  
    if (!hasCookie) {
        $cookiesBanner.classList.remove("hidden");
    }
  
    $cookiesBannerButton.addEventListener("click", () => {
        setCookie(cookieName, "closed");
        $cookiesBanner.remove();
    });
  })();
  
  // NAVBAR ACTIVED 
  var selector = '.nav li';
  
  $(selector).on('click', function(){
      $(selector).removeClass('active');
      $(this).addClass('active');
  });
